import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Layout,
  Pagination,
  Card,
} from '../components/common';

const Index = ({
  data,
  pageContext,
}) => {
  const posts = data.allGhostPost.edges;

  return (
    <Layout isHome>
      <div className="container">
        <section className="post-feed">
          {posts.map(({
            node: {
              id,
              published_at: date,
              title,
              feature_image: img,
              slug,
              tags,
              authors,
              excerpt,
            },
          }, i) => (
            <Card
              key={id}
              image={img}
              className={`post-card ${i === 0 ? 'large' : ''}`}
              tags={Object.values(tags).map(({ name }) => name)}
              title={title}
              excerpt={excerpt}
              avatar={authors[0].profile_image}
              author={authors[0].name}
              date={date}
              postLink={slug}
              authorLink={authors[0].slug}
            />
          ))}
        </section>

        <Pagination pageContext={pageContext} />
      </div>
    </Layout>
  );
};

Index.defaultProps = {
  pageContext: null,
};

Index.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          title
          excerpt
          feature_image
          published_at(formatString: "DD.MM.Y")
          slug
          tags {
            name
          }
          authors {
            name
            slug
            profile_image
          }
          id
        }
      }
    }
  }
`;
